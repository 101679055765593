<template>
  <div class="main-content">
    <div class="col-12" ref="details-wrapper">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
              <h4 class="blue-text font-weight-bold">
                <font-awesome-icon
                  class="mr-3"
                  :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
                  @click="$router.go(-1)"
                />
                <span class="details-title">依頼詳細を表示</span>
              </h4>
            </div>
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-6"
              v-if="pagination.data"
            >
              <span class="blue-text float-right">
                {{ pagination.data.current_page }}/{{ pagination.data.last_page }}
                枚
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="listingDetailsData">
        <div id="uploaded-image" class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div class="col-12">
                <p class="blue-text font-weight-bold">
                  <span v-if="listingDetailsData.status == 3" class="details-title">修正依頼した画像</span>
                  <span v-else class="details-title">アップロードした画像</span>
                </p>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-6">
                <div class="image-wrapper pb-5">
                  <div class="image">
                    <img
                      :src="getCurrentImageVersionUrl(listingDetailsData)"
                      onerror="this.src='/img/image-error.png'"
                      @click="stagingListing.request_category == 2 ? openImage(getCurrentImageVersionUrl(listingDetailsData)) : openModal(getCurrentImageVersionUrl(listingDetailsData))"
                    />
                  </div>
                  <div class="details-date">
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">依頼日時:</span>
                      <span>
                        {{
                          formatDate(
                            listingDetailsData.staging_request.billing.updated_at
                          )
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-6">
                <div class="details">
                  <div class="mb-3">
                    <p class="font-weight-bold m-0">画像詳細</p>
                  </div>
                  <div>
                    <p v-if="listingDetailsData.staging_request.request_category == 1" class="mb-2">注文カテゴリ: デジタルステージング</p>
                    <p v-if="listingDetailsData.staging_request.request_category == 2" class="mb-2">注文カテゴリ: 360°デジタルステージング</p>
                    <p v-if="listingDetailsData.staging_request.request_category == 3" class="mb-2">注文カテゴリ: 家具消し</p>
                    <p v-if="listingDetailsData.staging_request.request_category == 4" class="mb-2">注文カテゴリ: 商業用デジタルステージング</p>
                    <p v-if="listingDetailsData.staging_request.request_category == 5" class="mb-2">注文カテゴリ: デジタルリノベーション</p>
                    <p class="mb-2">物件名/号室: {{ listingDetailsData.property_name }}</p>
                    <p class="mb-2">
                      <span>画像名:</span>
                      <span>
                        {{
                          listingDetailsData.filename +
                          '.' +
                          listingDetailsData.file_extension
                        }}
                      </span>
                    </p>
                    <p v-if="listingDetailsData.staging_request.request_category != 5" class="mb-2">
                      <span>デザインスタイル: </span>
                      <span v-for="(style, i) in styleList" :key="i">
                        <span v-if="style.id == listingDetailsData.staging_request.furnishing_style_id" >{{ style.name }}</span>
                      </span>
                    </p> 
                    <p v-if="listingDetailsData.staging_request.request_category != 5" class="mb-2">
                      お部屋の種類:
                      {{
                        listingDetailsData.room_area
                          ? listingDetailsData.room_area.name
                          : ''
                      }}
                    </p>
                    <p class="mb-2" v-if="listingDetailsData.furnitures.length > 0">
                      <span>選択した家具:</span>
                      <span
                        v-for="(el, i) in listingDetailsData.furnitures"
                        :key="i"
                      >
                        <span v-if="el.furniture">
                          {{
                            listingDetailsData.furnitures.length !== i + 1
                              ? el.furniture.name + ','
                              : el.furniture.name
                          }}
                        </span>
                      </span>
                    </p>
                    <p class="mb-2" v-if="listingDetailsData.additional_all_options.length > 0 && listingDetailsData.status != 3">
                      <span>追加オプション:</span>
                      <span
                        v-for="(el, i) in listingDetailsData.additional_all_options"
                        :key="i"
                      >
                        {{
                          listingDetailsData.additional_options.length !== i + 1
                            ? el.additional_option.name + ','
                            : el.additional_option.name
                        }}
                      </span>
                    </p>
                    <p v-else-if="getConfirmOptions(listingDetailsData).length > 0">
                      <span>修正依頼追加オプション:</span>
                      <span
                        v-for="(op, i) in getConfirmOptions(listingDetailsData)"
                        :key="i"
                      >
                        {{
                            op.additional_option.name + ','
                        }}
                      </span>
                    </p>

                    <p v-if="getMessage(listingDetailsData) && listingDetailsData.status == 3">
                      <span>修正メッセージ:</span>
                      <span>
                        {{
                          getMessage(listingDetailsData)
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr
          class="mt-5"
          v-if="
            current_version &&
            (listingDetailsData.staging_request.status == 2 ||
              listingDetailsData.staging_request.status == 3)
          "
        />
        <div
          id="uploaded-image"
          class="row"
          v-if="
            current_version &&
            (listingDetailsData.staging_request.status == 2 ||
              listingDetailsData.staging_request.status == 3)
          "
        >
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div class="col-12 col-md-12 title-area">
                <div>
                  <span class="blue-text font-weight-bold details-title">
                    ステージング画像
                  </span>
                  <span v-if="stagingListing.request_category == 2">
                    こちらのURLから確認可能です。
                  </span>
                </div>
              </div>
              <div v-if="stagingListing.request_category != 2" class="col-12 col-md-8 title-area">
                <div>
                  <input class="checkbox" type="checkbox" v-model="isMix" />
                </div>
                <div class="download-text">
                  チェックを入れてダウンロードすると<br>画像にCG加工されている旨が記載されます
                </div>
              </div>
              <div v-else class="col-12 col-md-12 title-area">
                <div v-if="current_version" class="col-12 col-md-12">
                  現在のバージョン：
                  <b-form-input
                        class="form-input"
                        placeholder=""
                        :value="setImageUrl(current_version.result_file_url)"
                  ></b-form-input>
                </div>
              </div>
              <div v-if="stagingListing.request_category == 2" class="col-12 col-md-12 title-area">
                <div v-if="old_versions.length > 0 " class="col-12 col-md-12">
                  以前のバージョン：
                  <div v-for="(el, i) in old_versions" :key="i">
                    <b-form-input
                      class="form-input"
                      placeholder=""
                      :value="setImageUrl(el.result_file_url)"
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-md-12 col-lg-12 col-xl-6"
                v-if="current_version"
              >
                <div class="row">
                  <div class="col">
                    <p class="font-weight-bold">
                      <span class="details-title">現在のバージョン</span>
                    </p>
                  </div>
                </div>
                <div class="image-wrapper pb-3">
                  <div class="image">
                    <img
                      :src="current_version.result_file_url"
                      onerror="this.src='/img/image-error.png'"
                      @click="stagingListing.request_category == 2 ? openImage(current_version.result_file_url) : openModal(current_version.result_file_url)"
                    />
                    <a
                      href="#"
                      @click.prevent="
                        downloadItem(
                          current_version.result_file_path,
                          `SR-${listingDetailsData.staging_req_id}_${listingDetailsData.id}_current_version_${current_version.delivery_date}`
                        )
                      "
                    >
                      <div class="download-wrapper float-right">
                        <font-awesome-icon
                          :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                          :style="{
                            color: '#034EA1',
                            height: '36px',
                            width: '36px',
                            top: '-60px',
                          }"
                        />
                      </div>
                    </a>
                  </div>
                  <div class="details-date">
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">納品日時:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.delivery_date) }}
                      </span>
                    </p>
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">確認期限:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.deadline_date) }}
                      </span>
                    </p>
                  </div>
                  <div class="btn_qr" v-if="stagingListing.request_category == 2 && stagingListing.status == 3">
                    <button class="btn btn-ds blue white-text" v-waves.light @click="displayQr(current_version.result_file_url)">
                      QRコード
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="col-12 col-md-12 col-lg-12 col-xl-6"
                v-if="old_versions.length > 0"
              >
                <p class="font-weight-bold">
                  <span class="details-title">以前のバージョン</span>
                </p>
                <div class="image-wrapper pb-5">
                  <div class="image">
                    <b-carousel
                      class="img-carousel"
                      :interval="0"
                      :controls="old_versions.length > 1 ? true : false"
                      :indicators="old_versions.length > 1 ? true : false"
                      v-model="oldImageIndex"
                    >
                      <b-carousel-slide v-for="(el, i) in old_versions" :key="i">
                        <template #img>
                          <img
                            :src="el.result_file_url"
                            onerror="this.src='/img/image-error.png'"
                            @click="stagingListing.request_category == 2 ? openImage(el.result_file_url) : openModal(el.result_file_url)"
                          />
                        </template>
                        <a
                          href="#"
                          @click.prevent="
                            downloadItem(
                              el.result_file_path,
                              `SR-${listingDetailsData.staging_req_id}_${listingDetailsData.id}_${el.delivery_date}`
                            )
                          "
                        >
                          <div
                            class="download-wrapper float-right old-version-carousel"
                          >
                            <font-awesome-icon
                              :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                              :style="{
                                color: '#034EA1',
                                height: '36px',
                                width: '36px',
                                top: '-60px',
                              }"
                            />
                          </div>
                        </a>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                  <div class="details-date">
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">納品日時:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.delivery_date) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <pagination
          class="mt-5 mb-0"
          :data="pagination.data"
          :limit="pagination.limit"
          :show-disabled="pagination.showDisabled"
          :size="pagination.size"
          :align="pagination.align"
          @pagination-change-page="getResults"
        />
      </div>
      <div v-else>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row mt-4">
              <div class="col-12 mb-3">
                <b-skeleton width="20%"></b-skeleton>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
                <b-skeleton-img width="100%"></b-skeleton-img>
                <b-skeleton class="mt-3 mb-2" width="100%"></b-skeleton>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
                <div class="details">
                  <div class="mb-3">
                    <b-skeleton width="80%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="50%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="100%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="60%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="90%"></b-skeleton>
                  </div>
                  <div class="mb-3">
                    <b-skeleton width="40%"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <image-modal @closeModal="closeModal()" v-if="isOpenModal">
      <div class="bl_image">
        <img :src="modalImageUrl">
      </div>
    </image-modal>
  </div>
</template>

<script>
// Import Services
import { globalService_DownloadIMG } from '../../services/global';
import { cus_stagingService_GetAllSRItems, cus_stagingService_GetSRData, cus_stagingService_GetAllFunishingStyle } from '../../services/customer/staging';

// Import Moment JS
import moment from 'moment';

import QRCode from 'qrcode';

//import common
import { getRequestItemVersions } from '../../common/Functions';
import { formatDate, formatPrice } from '../../common/Utility';

// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | View',
    };
  },
  data() {
    return {
      stagingListing: null,
      listingDetailsData: null,
      old_versions: [],
      current_version: null,
      pagination: {
        data: null,
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'left',
      },
      isMix:true,
      isOpenModal: false,
      modalImageUrl: null,
      styleList: null,
      oldImageIndex: 0
    };
  },

  watch: {
    'pagination.limit'(newVal) {
      this.pagination.limit = parseInt(newVal);

      if (this.pagination.limit < 0) {
        this.pagination.limit = 0;
      }
    },
  },
  computed: {
    ...mapGetters(['currentRoute']),
  },
  components: {
    'image-modal': ImageModal,
  },
  mounted() {
    this.setListingDetailsData();
    this.getStyles();
  },

  methods: {
    openModal(imgUrl) {
      this.modalImageUrl = imgUrl;
      this.isOpenModal = true;
    },
    closeModal(){
      this.isOpenModal = false;
    },
    openImage(imgUrl){
      this.$router.push({
        name: 'anonymousRoute-image',
        query: { image: imgUrl },
      });
    },
    setImageUrl(imgUrl) {
      return window.location.host + '/image?image=' + imgUrl;
    },
    displayQr(imgUrl){
      const imageUrl = encodeURIComponent(imgUrl)
      const url = "http://localhost:8081/image?image="+imageUrl
      QRCode.toDataURL(url,{ width: 500}).then(code => {
        this.modalImageUrl = code;
        this.isOpenModal = true;
      }).catch(error => {
        console.log(error)
      })
    },
    formatDate,
    formatPrice,
    setListingDetailsData() {
      let _this = this;
      _this.getResults();
    },

    getStyles() {
      cus_stagingService_GetAllFunishingStyle({
        token: localStorage.getItem('_accessToken')
      })
        .then((response) => {
          this.styleList = response.data
          console.log('styleList');
          console.log(this.styleList);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getResults(page) {
      let _this = this;

      if (!page) {
        page = 1;
      }

      let stagingRequestID = _this.$route.params.id;
      let accessToken = localStorage.getItem('_accessToken');

      _this.stagingListing = null;
      _this.listingDetailsData = null;

      cus_stagingService_GetSRData({
        id: stagingRequestID,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.stagingListing = data
        })
        .catch((error) => {
          console.log(error);
        });

      cus_stagingService_GetAllSRItems({
        id: stagingRequestID,
        paginated: 1,
        page_size: 1,
        page: page ? page : 1,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          if (data.data.length > 0) {
            _this.listingDetailsData = data.data[0];
            console.log(_this.listingDetailsData)
            _this.pagination.data = data;

            //get staging versions
            const { current_version, prev_versions } = getRequestItemVersions(
              data.data[0]
            );
            _this.current_version = current_version;
            console.log('test');
            console.log(prev_versions);
            for (var i=0;i<prev_versions.length;i++){
              if (prev_versions[i].user_type == undefined || prev_versions[i].user_type == 0 ){
                _this.old_versions.push(prev_versions[i]);
              }
            }
          }
        })

        .catch((error) => {
          console.log(error);
        });

      _this.$store.state.currentPaginationPage = page;

      //scroll view on top
      var detailsWrapper = _this.$refs['details-wrapper'];
      if (detailsWrapper) {
        detailsWrapper.scrollIntoView({ block: 'center', inline: 'nearest' });
      }
    },

    downloadItem(path, filename) {
      let accessToken = localStorage.getItem('_accessToken');

      globalService_DownloadIMG({
        token: accessToken,
        path: path,
        isMix:this.isMix,
      }).then((response) => {
        let url = window.URL.createObjectURL(response.data);
        let link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);

        link.click();
      });
    },
    getMessage(obj) {
      // correctionsがありかつmessageに値が入っていた場合表示する
      let message = '';
      let arr = obj.corrections;
      if (arr.length > 0) {
        message = arr[arr.length - 1]['message'];
      }
      return message;
    },
    getCurrentImageVersionUrl(requestItem) {
      if (requestItem.status != 3) {
        return requestItem.file_url;
      }
      const { current_version } = getRequestItemVersions(requestItem);
      return current_version ? current_version.result_file_url : requestItem.file_url;
    },
    getConfirmOptions(obj) {
      console.log('obj');
      if (obj.status != 3) {
        return obj.additional_all_options;
      }
      let confirm = obj.corrections;
      if (confirm.length == 0) {
        return obj.additional_all_options;
      }
      let options = obj.additional_all_options;
      let returnOption = options.filter(option => {
        return option.confirm_count === confirm.length;
      });

      console.log(returnOption);
      return returnOption;

    },
  },
};
</script>

<style scoped>
.form-input {
  height:30px;
  width: 75%;
}

.main-content {
  padding: 110px 60px 150px 350px;
}
.details-title {
  font-size: 19px;
  font-style: normal normal medium 19px/23px Roboto;
}
.details-title {
  margin-bottom: 43px;
}
.details span,
.details p {
  font-size: 16px;
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 120px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 120px 10px 80px 10px;
  }
}

#uploaded-image {
  margin-top: 40px;
}

#uploaded-image .image {
  height: 340px;
  background: #ececec;
  border-radius: 15px;
  padding: 0;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 1440px) {
  #uploaded-image .image {
    height: 200px;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .image {
    height: 400px;
  }
}

@media only screen and (max-width: 425px) {
  #uploaded-image .image {
    height: 250px;
  }
}

#uploaded-image .image img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
}

#uploaded-image .image .img-carousel {
  height: 100%;
  border-radius: 15px;
  background: #e9e9e9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#uploaded-image .timestamp {
  margin: 10px 0 0 20px;
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .timestamp {
    margin: 10px 0 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .details {
    margin-top: 60px;
  }
}

.download-wrapper {
  position: absolute;
  right: 36px;
  bottom: 12px;
  background: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.download-wrapper.old-version-carousel {
  right: -40px;
  bottom: -8px;
}

.image-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
}

.title-area {
  display: flex;
  align-items: end;
  margin: -16px 0 16px;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  accent-color: red;
}

.download-text {
  font-size: 16px;
  color: red;
}

@media only screen and (max-width: 767px) {
  .download-text {
    margin-top: 5px;
    font-size: 14px;
  }
}

.btn_qr{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.btn_qr .btn{
  width: 80%;
}

.bl_image{
  display: flex;
  justify-content: center;
}
</style>
